import axios from "axios";

let base_url = localStorage.getItem('baseapi')
// let user = JSON.parse(localStorage.getItem('user'))
// let id_proyek = JSON.parse(localStorage.getItem('proyek') || '').id_proyek

export async function getAsignment(user,id_proyek) {
    
    const assign_member = await axios.get(base_url + "api/assign_member?filter=id_user,=," + user.id+';id_proyek,=,'+id_proyek, {
        headers: {
          Authorization: `Bearer ` + user.accessToken
        }
      })
    // const assign_member = await axios({
    //     method: "get",
    //     url: "",
    //     baseURL: `${base_url}api/assign_member?filter=id_proyek,=,`+id_proyek,
    //     headers: {
    //         Authorization: `Bearer ` + user.accessToken
    //     }
    // });

    console.log(assign_member)
    return assign_member.data;
}