<script>
// import simplebar from "simplebar-vue";
import i18n from "../i18n";
import axios from "axios";
import Swal from "sweetalert2"

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      baseapi: localStorage.getItem("baseapi"),
      dataproyek: JSON.parse(localStorage.getItem('proyek')),
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French"
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish"
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese"
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic"
        }
      ],
      current_language: "en",
      listProyek: []
    };
  },
  // components: { simplebar },
  mounted() {
    this.loadData()
  },
  methods: {
    logout() {
      Swal.fire({
        title: 'Logout',
        text: "Are you sure to logout from the system?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText:'No'
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("user")
          localStorage.removeItem("proyek")
          localStorage.removeItem("idProyek")
          this.$router.push('/logout');
        }
      })
    },
    loadData() {
      axios.get(this.baseapi + "api/assign_member?filter=id_user,=," + this.user.id, {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.listProyek = response.data.data
      })
    },
    switchProject(data) {
      localStorage.setItem('proyek', JSON.stringify(data));
      localStorage.setItem('idProyek', data.id_proyek)
      this.$nextTick(() => {
        this.$router.go(0);
        this.$forceUpdate();
      });
      
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="#" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logomroyeksm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logoabuabu.png" alt height="40" width="100" />
            </span>
          </a>

          <a href="#" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logomroyeksm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logoputih.png" alt height="40" width="100" />
            </span>
          </a>
        </div>

        <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="ri-search-line"></span>
          </div>
        </form> -->

        <b-dropdown v-if="user.role == 'user'" variant="black" class="dropdown-mega d-none d-lg-block ml-2"
          toggle-class="header-item" menu-class="dropdown-megamenu">
          <template v-slot:button-content>
            <span v-if="dataproyek.nama_proyek.length < 25"><strong>{{ dataproyek.nama_proyek }}</strong></span>
            <span v-else><strong>{{ dataproyek.nama_proyek.substr(0,23)+ '...' }}</strong></span>
            <i class="mdi mdi-chevron-down"></i>
          </template>
          <span>My Project</span>
          <hr>
          <div class="row" style="width: 300px;">

            <div class="col-sm-12">
              <b-list-group>
                <b-list-group-item href="#" v-for="(data, index) in listProyek" :key="index"
                  :active="data.id == dataproyek.id" class="d-flex  align-items-center" @click="switchProject(data)" v-b-tooltip.hover data-placement="right" :title="data.nama_proyek">

                  <div class="avatar-xs mr-1">
                    <span class="avatar-title rounded-circle bg-soft-success text-success">{{ data.nama_proyek.substr(0, 1)
                    }}</span>
                  </div>
                  <span v-if="data.nama_proyek.length < 25">{{ data.nama_proyek }}</span>
                  <span v-else>{{ data.nama_proyek.substr(0,23)+ '...' }}</span>


                </b-list-group-item>

              </b-list-group>
            </div>
          </div>
          <hr>
          <div class="d-flex justify-content-between">
            <!-- <span><i class="mdi mdi-plus"></i> New Project</span> -->
            <router-link to="/project"><span><i class="mdi mdi-view-grid"></i> View All</span></router-link>
          </div>

        </b-dropdown>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ri-search-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown">
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon waves-effect" @click="initFullScreen">
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>


        <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
          <template v-slot:button-content>
            <!-- <span class="rounded-circle header-profile-user rounded-circle bg-light text-body">{{ user.nama.charAt(0) }}</span> -->
            <img class="rounded-circle header-profile-user" :src="user.avatar" alt="Header Avatar" />
            <span class="d-none d-xl-inline-block ml-1">{{ user.nama }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <a class="dropdown-item" href="/profile">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a>
          <!-- item-->
          <!-- <a class="dropdown-item" href="#">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a>
          <a class="dropdown-item" href="#">
            <i class="ri-wallet-2-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.mywallet') }}
          </a>
          
          <a class="dropdown-item" href="#">
            <i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ $t('navbar.dropdown.kevin.list.lockscreen') }}
          </a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <a class="dropdown-item text-danger" type="button" @click="logout()">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.kevin.list.logout') }}
          </a>
        </b-dropdown>

        <!-- <div class="dropdown d-inline-block">
                <button
                  type="button"
                  class="btn header-item noti-icon right-bar-toggle waves-effect toggle-right"
                  @click="toggleRightSidebar"
                >
                  <i class="ri-settings-2-line toggle-right"></i>
                </button>
              </div> -->
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>