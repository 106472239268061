export var menuItemsAdmin = [
        {
            id: 1,
            label: "Master Data",
            isTitle: true
        },
        {
            id: 2,
            label: 'Dashboard',
            icon: 'ri-dashboard-line',
            badge: {
                variant: "success",
                text: "menuitems.dashboard.badge"
            },
            link: '/'
        },
        {
            id: 4,
            label: 'User',
            icon: 'fas fa-user-friends',
            link: '/user'
        },
        {
            id: 5,
            label: 'Role',
            icon: 'fas fa-unlock-alt',
            link: '/role'
        },
        {
            id: 6,
            label: 'Package',
            icon: 'fas fa-book',
            link: '/master/paket'
        },
        {
            id: 7,
            label: 'Variable',
            icon: 'fas fa-list',
            link: '/master/variable'
        },
        {
            id: 8,
            label: 'Ability',
            icon: 'fas fa-link',
            link: '/master/ability'
        },
        {
            id: 9,
            label: 'Transaction',
            icon: 'fas fa-money-bill',
            link: '/master/transaksi'
        },
        
    ]
